import React, { useEffect, useState } from 'react';
import * as helpers from "../../utils/utils.js"
import "./bookingTable.css"
import BookingDetails from '../bookingDetails/bookingDetails.js';


const BookingTable = ({ units, bookings, client }) => {
  const [sortedSlots, setSortedSlots] = useState([]);
  
  const handleRowClick = (id) => {
      // setExpandedRow(expandedRow === id ? null : id);
  };
  

  useEffect(() => {
    const updatedSortedSlots = [];

    const sortSlots = async () => {
      for (const [key, value] of Object.entries(bookings)) {
        const customerResponse = await helpers.get(`/clients/${client}/customers/${value.customerId}`);
        value.slots.forEach(slot => {
          updatedSortedSlots.push({...slot, bookingId: key, customer: customerResponse});
        })
      }

      const resolvedData = await Promise.all(updatedSortedSlots);
      resolvedData.sort((a,b) => a.date - b.date || a.unitId - b.unitId)
      setSortedSlots(resolvedData);
    };

    sortSlots();
  }, [bookings, client])

  
  return (
    <div className='booking-date-container main-border'>
        <div className='container date-details'>

            {sortedSlots.length > 0 && (
            <table className="table date-table">
              <thead>
                <tr>
                <th scope="col">Date</th>
                <th scope="col">Name</th>
                {/* <th scope="col">Price</th> */}
                <th scope="col">Unit</th>
                </tr>
              </thead>
              <tbody>
                {sortedSlots.map((slot) => { 
                  const formattedDate = new Date(
                    parseInt(slot.date),
                  ).toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                    timeZone: "UTC",
                  });

                  const customer = slot.customer;
                  // const price = slot.costs.deposit.amount + slot.costs.remaining.amount;
                  const unit = units.find((unit) => unit.id === slot.unitId);
                  return (
                    <React.Fragment key={Date.now()}>
                    <tr className="booking-date-row" onClick={() => handleRowClick(slot.bookingId)}>
                        <td className='date'>{formattedDate}</td>
                        <td className='name'>{customer.firstName + " " + customer.lastName}</td>
                        {/* <td className='price'>${price}</td> */}
                        <td className='unit'>{unit.name}</td>
                    </tr>
                    {/* {expandedRow === bookDate.id && (
                        <tr>
                            <td colSpan="5">
                                <div className="expanded-content">
                                    <BookingDetails 
                                        booking_id={bookDate.booking_id} 
                                        bookingDates={bookingDates.filter((date) => date.booking_id === bookDate.booking_id)}
                                        setBookingDates={setBookingDates}
                                        units={units}
                                    />
                                </div>
                            </td>
                        </tr>
                    )} */}
                    </React.Fragment>
                )})}
                  
              </tbody>
            </table>
            )}
        </div>
      
      
    </div>
  );
};

export default BookingTable;
