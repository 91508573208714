import React, { useEffect, useState } from "react";
// import "./Admin.css";
import * as helpers from "../utils/utils.js";
import BookingTable from "../components/bookingTable/bookingTable.js";




function Admin() {
    const [client, setClient] = useState("torchrentals");
    const [bookings, setBookings] = useState({});
    const [units, setUnits] = useState({});

    useEffect(() => {
    
        // async function getBookingDates() {
        //     const result = await helpers.get(`/booking_dates?client_id=${client}`);
        //     return result ? result.sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
        // }

        async function getBookings() {
            const result = await helpers.get(`/clients/${client}/bookings?`);
            return result ?? [];
        }

        async function getUnits() {
            const result = await helpers.get(`/clients/${client}/units`);
            return result ?? [];
        }


        if (client) {

            // loads all bookings for this client
            getBookings().then((result) => {
                setBookings(result)
            }).catch((console.error("Could Not Retreive Bookings")))
            
            // loads all units for this client
            getUnits().then((result) => {
                setUnits(result);
            }).catch((console.error("Could Not Retreive Units ")))

        }

    }, [client])



    return (
        <>
       
        {/* <BookingDateTable
            units={units}
            bookingDates={bookingDates}
            bookings={bookings}
        /> */}

        {/* <a className='btn' href={`/api/bookkeep?client_id=${String(client)}`}>Download Bookkeeping CSV</a> */}

        <BookingTable
            units={units}
            bookings={bookings}
            client={client}
        />

        {/* 
        <PriceDates
            units={units}
            priceDates={priceDates}
        /> */}
        </>
    )

}

export default Admin;