const baseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
export const url = baseUrl + "/api";
// export const url = `http://localhost:5001`;


export const dateToFormattedString = (date) => {
  const date_str = date.toISOString().slice(0, 16);
  return date_str;
};

export const formattedStringToDate = (date_str) => {
  const newDate = new Date(date_str);
  return newDate;
};

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const sortUnits = (a, b) => {
  if (a.unit_id > b.unit_id) {
    return 1;
  } else {
    return -1;
  }
};

export const formatTime = (hours, minutes) => {
  // Ensure the hours and minutes are in two-digit format (e.g., 9 becomes "09")
  const period = hours >= 12 ? "PM" : "AM";
  const twelveHour = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${twelveHour}:${formattedMinutes} ${period}`;
};

export const minutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return { hours: hours, minutes: remainingMinutes };
};

export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const put = async (route, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    headers: myHeaders,
    method: "PUT",
    body: data,
  };

  try {
    const response = await fetch(`${url + route}`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const post = async (route, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    headers: myHeaders,
    method: "POST",
    body: JSON.stringify({ ...data }),
    redirect: "follow",
  };

  try {
    const response = await fetch(`${url + route}`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const get = async (route) => {
  const requestOptions = {
    method: "GET",
  };

  try {
    const response = await fetch(`${url + route}`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const del = async (route) => {
  const requestOptions = {
    method: "DELETE",
  };

  try {
    const response = await fetch(`${url + route}`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getPDF = async (route) => {
  const requestOptions = {
    method: "GET",
  };

  try {
    const response = await fetch(`${url + route}`, requestOptions);
    const result = await response;
    return result;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getDateHasPassed = (date) => {
  const cutoffTime = new Date().setUTCHours(22, 0, 0, 0); // 6pm EST cutoff for booking
  const tileDate = date.valueOf();
  const todayDate = new Date();

  const tileIsToday =
    date.getUTCDate() === todayDate.getUTCDate() &&
    date.getUTCMonth() === todayDate.getUTCMonth() &&
    date.getUTCFullYear() === todayDate.getUTCFullYear();

  const cutoffHasPassed = tileIsToday && todayDate.valueOf() > cutoffTime; // if its after the cutoff time can't book today
  return tileDate < todayDate.valueOf() - 86400000 || cutoffHasPassed;

}
