// Home.js

import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import ReactLoading from "react-loading";
import "./Home.css";
import * as helpers from "../utils/utils.js";
import RenterInfoForm from "../components/renterInfoForm/renterInfoForm.js";
import UnitPaymentForm from "../components/unitPaymentForm/unitPaymentForm.js";
import UnitTileList from "../components/unitTileList/unitTileList.js";
import PriceDisplay from "../components/priceDisplay/priceDisplay.js";
import BookingCalendar from "../components/bookingCalendar/bookingCalendar.js";
import ProgressBar from "../components/progressBar/progressBar.js";
import NavBar from "../components/navBar/navBar.js";
import RenterInfoDisplay from "../components/renterInfoDisplay/renterInfoDisplay.js";
import BookingComplete from "../components/bookingComplete/bookingComplete.js";

function Home({ client_id, admin }) {
  const [squareCredentials, setSquareCredentials] = useState({}); // dict of square credentials: app_id, location_id
  const [selectedDates, setSelectedDates] = useState([]); // list of selected Dates
  const [availableSlots, setAvailableSlots] = useState([]); // list of available slots
  const [selectedSlots, setSelectedSlots] = useState([]); // list of SlotIds that are selected
  const [units, setUnits] = useState({}); // list of unit info by unit_id for all clientUnits
  const [client, setClient] = useState({})
  const [prices, setPrices] = useState({}); // list of prices
  const [renterInfo, setRenterInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  }); // dict of renter info
  const [discounts, setDiscounts] = useState([]); // list of valid discounts/PROMO applied
  const [costs, setCosts] = useState({}); // dict of cost keys: [deposit, price, discount]
  const [step, setStep] = useState(0); // 0:selections, 1:info, 2:payment, 3:complete, 4:error, 5:loading
  const [renterFormErrors, setRenterFormErrors] = useState({}); // contains errors from renter form
  const [bookingComplete, setBookingComplete] = useState(false);
  const [appliedDiscounts, setAppliedDiscounts] = useState({}); // holds ids of applied discounts with the amount to be subtracted { discountId: amount }
  // useEffect(() => {
  //   console.log("selectedSlots", selectedSlots);
  //   console.log("visibleSlots", availableSlots);
  // }, [selectedSlots, availableSlots]);

  // perform booking actions
  useEffect(() => {
    const createBooking = async () => {
      if (costs?.deposit?.payment?.status !== "COMPLETED") return;

      try {
        // Create or get user
        const result = await helpers.post(
          `/clients/${client_id}/customers`,
          renterInfo,
        );
        const customerId = result.customer.id;

        // Create the booking object
        const newBooking = {
          customerId: customerId,
          status: "confirmed",
          createdAt: Date.now(),
          createdBy: admin ? "admin" : "customer",
          costs: { ...costs },
          slots: [...selectedSlots],
          discounts: { ...appliedDiscounts },
        };

        // Save or process the booking
        const bookingResponse = await helpers.post(
          `/clients/${client_id}/bookings`,
          newBooking,
        );

        const emailResponse = await helpers.post(
          `/clients/${client_id}/send-confirmation?bookingId=${bookingResponse.booking.id}`,
        );

        setBookingComplete(true);
        setStep(3);
      } catch (error) {
        console.error("Error creating booking:", error);
        setStep(4);
      }
    };

    createBooking();
  }, [costs, client_id, admin, renterInfo, appliedDiscounts, selectedSlots]);

  ////// Get Unit Info and Set Square Credentials whenever client changes
  useEffect(() => {
    helpers.get(`/clients/${client_id}`).then((result) => {
      setClient(result);
    });

    helpers.get(`/clients/${client_id}/units`).then((result) => {
      const updatedUnits = {};
      result.forEach((unit) => {
        updatedUnits[unit.id] = unit;
      });

      setUnits(updatedUnits);
    });

    helpers.get(`/clients/${client_id}/prices`).then((result) => {
      const updatedPrices = {};

      result.forEach((price) => {
        updatedPrices[price.id] = price;
      });

      setPrices(updatedPrices);
    });

    helpers.get(`/clients/${client_id}/discounts`).then((result) => {
      const updatedDiscounts = {};

      result.forEach((discount) => {
        updatedDiscounts[discount.id] = discount;
      });

      setDiscounts(updatedDiscounts);
    });

    helpers.get(`/clients/${client_id}/credentials/square`).then((result) => {
      setSquareCredentials(result);
    });
  }, [client_id]);

  // make sure that slots are only included if date is selected
  useEffect(() => {
    setSelectedSlots((prevSlots) =>
      prevSlots.filter((slot) =>
        selectedDates.includes(slot.date),
      ),
    );
  }, [selectedDates, setSelectedSlots]);

  /////// Validate renter info
  useEffect(() => {
    function validateRenterInfo(renterInfo) {
      let errors = {};

      if (admin) {
        setRenterFormErrors(errors);
        return;
      }

      for (let key of Object.keys(renterInfo)) {
        const value = renterInfo[key];

        if (value === "") {
          errors[key] = "This is a required field.";
        } else {
          if (key === "firstName" || key === "lastName") {
            if (!/^[a-zA-Z]+(?:[-'’][a-zA-Z]+)?$/.test(value)) {
              errors[key] = "Please enter a valid " + key;
            }
          } else if (key === "email") {
            if (
              !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                value,
              )
            ) {
              errors[key] =
                "Please enter a valid email address (example@gmail.com)";
            }
          } else if (key === "phone") {
            if (!/^\d{10}$/.test(value)) {
              errors[key] =
                "Please enter a valid US 10-digit phone number (0000000000)";
            }
          } else if (key === "address" || key === "city") {
            if (!/^[a-zA-Z0-9\s,'’#\-\.]+$/.test(value)) {
              errors[key] = "Please enter a valid " + key;
            }
          } else if (key === "zip") {
            if (!/^\d{5}(?:-\d{4})?$/.test(value)) {
              errors[key] = "Please enter a valid zip code (00000)";
            }
          }
        }
      }

      setRenterFormErrors(errors);
    }

    validateRenterInfo(renterInfo);
  }, [renterInfo, admin]);

  return (
    <>
      <ProgressBar
        step={step}
        setStep={setStep}
        selectionsDone={selectedSlots?.length > 0}
        infoDone={
          renterInfo.lastName !== "" &&
          Object.values(renterFormErrors).length === 0
        }
        complete={step === 3}
        error={step === 4}
      />
      <div className="booking-container">
        {step === 0 && (
          <>
            <BookingCalendar
              client_id={client_id}
              availableSlots={availableSlots}
              setAvailableSlots={setAvailableSlots}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
            />

            <UnitTileList
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              availableSlots={availableSlots}
              setAvailableSlots={setAvailableSlots}
              selectedSlots={selectedSlots}
              setSelectedSlots={setSelectedSlots}
              units={units}
              prices={prices}
            />

            {selectedSlots.length > 0 && <PriceDisplay
              selectedSlots={selectedSlots}
              units={units}
              discounts={discounts}
              appliedDiscounts={appliedDiscounts}
              setAppliedDiscounts={setAppliedDiscounts}
              setCosts={setCosts}
              prices={prices}
            />}
          </>
        )}

        {step === 1 && (
          <>
            <PriceDisplay
              selectedSlots={selectedSlots}
              units={units}
              discounts={discounts}
              appliedDiscounts={appliedDiscounts}
              setAppliedDiscounts={setAppliedDiscounts}
              setCosts={setCosts}
              setStep={setStep}
              prices={prices}
            />

            <RenterInfoForm
              setRenterInfo={setRenterInfo}
              renterInfo={renterInfo}
              errors={renterFormErrors}
              setErrors={setRenterFormErrors}
              setStep={setStep}
            />
          </>
        )}

        {step === 2 && (
          <>
            <PriceDisplay
              selectedSlots={selectedSlots}
              units={units}
              discounts={discounts}
              appliedDiscounts={appliedDiscounts}
              setAppliedDiscounts={setAppliedDiscounts}
              setCosts={setCosts}
              setStep={setStep}
              prices={prices}
            />

            <RenterInfoDisplay renterInfo={renterInfo} setStep={setStep} />

            <UnitPaymentForm
              deposit={costs.deposit.amount}
              setCosts={setCosts}
              setStep={setStep}
              step={step}
              squareCredentials={squareCredentials}
              client_id={client_id}
              selectedSlots={selectedSlots}
              terms={client.terms}
            />
            {admin && (
              <button
                className="btn"
                onClick={() => {
                  setStep(5);
                  setCosts((prevCost) => ({
                    ...prevCost,
                    deposit: {
                      ...prevCost?.deposit,
                      payment: {
                        ...prevCost?.deposit?.payment,
                        status: "COMPLETED",
                      },
                    },
                  }));
                }}
              >
                Book Without Paying
              </button>
            )}
          </>
        )}

        {step === 3 && (
          <>
            <PriceDisplay
              selectedSlots={selectedSlots}
              units={units}
              discounts={discounts}
              appliedDiscounts={appliedDiscounts}
              setAppliedDiscounts={setAppliedDiscounts}
              setCosts={setCosts}
              prices={prices}
              hideDiscountForm={true}
            />

            <RenterInfoDisplay renterInfo={renterInfo} />

            <BookingComplete email={renterInfo.email} />
          </>
        )}

        {step === 4 && (
          <>
            <Alert variant={"danger"}>
              <p>
                There is a problem with your booking, please check your card
                details and try again. If the issue persists please contact us.
              </p>
            </Alert>
          </>
        )}

        {step === 5 && (
          <>
            <ReactLoading
              className="spinner"
              type="spinningBubbles"
              color="#0000FF"
              height={100}
              width={50}
            />
            <p className="keep-loading-instuctions">
              Booking Processing: Do Not Leave This Page, this may take up to 1
              minute
            </p>
            <p className="keep-loading-instuctions">
              <strong>Payment Status:</strong>{" "}
              {costs?.deposit?.payment?.status !== "COMPLETED"
                ? "In-Progress...Please wait"
                : "Complete"}
            </p>
            <p className="keep-loading-instuctions">
              <strong>Booking Status:</strong>{" "}
              {!bookingComplete ? "In-Progress...Please wait" : "Complete"}
            </p>
          </>
        )}
      </div>
      {step < 2 && (
        <NavBar
          step={step}
          setStep={setStep}
          disabled={() => {
            if (step === 0 && selectedSlots?.length > 0) {
              return false;
            }

            if (
              step === 1 &&
              renterInfo.firstName !== "" &&
              Object.values(renterFormErrors)?.length === 0
            ) {
              return false;
            }

            return true;
          }}
        />
      )}
    </>
  );

  //
}

export default Home;
